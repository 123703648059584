main {
    text-align: center;
    vertical-align: center;
    line-height: 50px;
  }
  
  h1 {
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
  }
  
  h2 {
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    line-height: 25px;
  }
  
  p {
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
  }
  
  .proTex {
    display: inline-block;
  }
  
  h1, p {
    margin: 0;
  }
  
  button, input {
    color: black;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: center;
    width: 150px;
    height: 50px;
    margin: 10px;
    font-size: 1.1em;
    border-radius: 25px;
    max-width: 80%;
  }
  
  input {
    margin: 0;
  }
  
  .sentence {
    width: 97%;
  }
  
  .profane {
    width: 8%;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  
  .customize {
    width: 300px;
  }
  
  button:hover {
    background-color: #00e5e5;
    color: white;
  }
  
  body {
      display: flex;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
      margin: auto;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      overflow: auto;
      background: linear-gradient(315deg, rgba(12,121,242,1) 0%, rgba(13,189,252,1) 25%, rgba(0,229,229,1) 50%, rgba(12,242,70,1) 75%, rgba(13,252,185,1) 100%);
      animation: gradient 45s ease infinite;
      background-size: 400% 400%;
      background-attachment: fixed;
  }
  
  @keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }
  
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 0;
  }
  
  li {
    color: white; /* set the text color to white */
    margin: 10px;
    text-align: center;
  }
  
  footer {
    opacity: 0.7; /* Set the opacity for the footer */
    text-align: center;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
  }
  
  footer a {
    color: #ffffff;
    text-decoration: none;
  }
  
  footer a:hover {
    color: #eeeeee;
  }